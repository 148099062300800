<template>
    <LoginLayout>
        <LoginForm />
    </LoginLayout>
</template>

<script>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import '@/assets/css/darmin/style.css';
import LoginLayout from '@/layouts/Login.layout.vue';

import LoginForm from '@/components/login/LoginForm.vue';

export default {
    name: 'LoginView',
    components: { LoginLayout, LoginForm },
    setup() {
        const store = useStore();
        const router = useRouter();
        onMounted(() => {
            if (store.state.Auth.user !== null) {
                router.push({ name: 'Home' });
            }
        });
    },
};
</script>
