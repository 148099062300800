<template>
    <div>
        <!-- Page Sidebar Start-->
        <SidebarContainer
            class="sidebar-wrapper"
            :class="{ closed: isMenuCollapsed }"
            @on-toggle-menu="$emit('on-toggle-menu')"
            @onClickIten="$emit('onClickIten')"
        ></SidebarContainer>
        <!-- Page Sidebar Ends-->
        <TabsContainer :class="{ closed: isMenuCollapsed, 'd-print-none': true }" />
        <PageBody class="body-wrapper" :class="{ closed: isMenuCollapsed }" />
        <!-- footer start-->
        <!-- <FooterContainer></FooterContainer> -->
        <!-- footer ends-->
    </div>
</template>

<script>
import SidebarContainer from './sidebar/SidebarContainer.vue';
import TabsContainer from './tabs/TabsContainer.vue';
import PageBody from './PageBody.vue';
// import FooterContainer from './footer/FooterContainer.vue';

export default {
    components: {
        SidebarContainer,
        TabsContainer,
        PageBody,
        // FooterContainer,
    },
    props: {
        isMenuCollapsed: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
};
</script>

<style scoped>
div.sidebar-wrapper {
    position: fixed;
    top: 0;
    z-index: 9;
    height: auto;
    line-height: inherit;
    background: #fff;
    width: 280px;
    text-align: left;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    -webkit-box-shadow: 0 0 21px 0 rgb(89 102 122 / 10%);
    box-shadow: 0 0 21px 0 rgb(89 102 122 / 10%);
    height: 100vh;
}
div.sidebar-wrapper.closed {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-left: -330px;
}
div.tab-wrapper.closed {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-left: 0px;
}
div.darmin.page-body.body-wrapper.closed {
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-left: 0px;
    width: 100%;
}
@media screen and (max-width: 902px) {
    div.darmin.page-body.body-wrapper {
        margin-left: 0px !important;
        width: calc(100%) !important;
    }
}
</style>
