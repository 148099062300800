import { ref } from 'vue';
import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.VUE_APP_MAIN_SERVICE}`,
});
const parameters = ref([]);

export default function useParameters() {
    const reloadParameters = async () => {
        const res = await api.get('/parameters');
        if (res.status === 200) {
            // console.log(res.data);
            parameters.value = res.data.items;
        } else {
            throw new Error(res.data.errors);
        }
    };

    const getParameters = async () => {
        if (parameters.value.length === 0) {
            await reloadParameters();
        }
    };

    const returnParameter = (name) => {
        const parameter = parameters.value.find((p) => p.name === name);
        if (parameter) {
            return parameter.value;
        }
        return undefined;
    };

    return { parameters, getParameters, returnParameter };
}
