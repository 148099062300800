import instance from '@/services/useAxios';

const rejectConnection = (error) => {
    throw error.response;
};

const handleError = async (error, store) => {
    const originalConfig = error.config;
    if (originalConfig.url !== '/auth/login' && error.response) {
        // Access Token was expired
        if (error.response.status === 401 && !originalConfig.retryFlag) {
            originalConfig.retryFlag = true;
            try {
                await store.dispatch('Auth/doRefreshToken');
                return instance(originalConfig);
            } catch (_error) {
                store.dispatch('Auth/logout').then(() => {
                    // eslint-disable-next-line no-restricted-globals
                    location.reload();
                });
                throw _error.response;
            }
        }
    }
    if (error.code === 'ERR_NETWORK') {
        await store.dispatch('Auth/logout').finally(() => {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
        });
    }
    throw error.response;
};

const setup = (store) => {
    instance.interceptors.request.use((config) => {
        const token = store.getters['Auth/getToken'];
        if (token) {
            // eslint-disable-next-line no-param-reassign
            config.headers['x-token'] = token; // for Node.js Express back-end
        }
        return config;
    }, rejectConnection);
    instance.interceptors.response.use(
        (res) => res,
        // eslint-disable-next-line comma-dangle
        (error) => handleError(error, store)
    );
};

export default setup;
