<template>
    <div class="page-wrapper compact-wrapper" id="pageWrapper" v-if="verfied">
        <!-- Page Header Start-->
        <HeaderContainer class="page-header d-print-none" :class="{ closed: isSbCollapsed }" @on-toggle-menu="toggleSb"
            :isMenuCollapsed="isSbCollapsed" />
        <!-- Page Header Ends -->
        <!-- Page Body Start-->
        <BodyWarpper :isMenuCollapsed="isSbCollapsed" @on-toggle-menu="toggleSb" class="page-body-wrapper"
            @click="closeSb" @onClickIten="closeSb"></BodyWarpper>
        <!-- Page Body Ends-->
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, onBeforeMount, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useSnackbar } from 'vue3-snackbar';
import useSocket from '@/services/useSocket';
import useResize from '@/services/useResize';
import HeaderContainer from '@/components/dashboard/header/HeaderContainer.vue';
import BodyWarpper from '@/components/dashboard/body/BodyWarpper.vue';

export default {
    name: 'App',
    components: {
        HeaderContainer,
        BodyWarpper,
    },
    setup() {
        const router = useRouter();
        const store = useStore();
        const { createSocket } = useSocket();
        const snackbar = useSnackbar();
        const { width } = useResize();
        const verfied = ref(false);
        const isSbCollapsed = ref(false);

        const toggleSb = () => {
            isSbCollapsed.value = !isSbCollapsed.value;
        };
        const closeSb = (e) => {
            const flag =
                e && e.type === 'click'
                    ? e.path?.filter((i) => {
                        try {
                            return i.classList.contains('sidebar-wrapper');
                        } catch (error) {
                            return false;
                        }
                    })
                    : [];
            if (width.value <= 992 && flag?.length === 0) {
                isSbCollapsed.value = true;
            }
        };

        onBeforeMount(async () => {
            if (store.state.Auth.user === null) {
                router.push({ name: 'Login' });
            } else {
                await store
                    .dispatch('doVerifySession')
                    .then(() => {
                        verfied.value = true;
                    })
                    .catch(() => {
                        store.dispatch('Auth/logout').finally(() => router.push({ name: 'Login' }));
                    });
            }
        });
        onMounted(() => {
            store.dispatch('ContentManager/openHome');
            const socket = createSocket(store.getters['Auth/getToken']);
            socket.on('connect', () => {
                snackbar.add({
                    type: 'info',
                    text: 'Bienvenido',
                });
            });
            if (width.value <= 992) {
                isSbCollapsed.value = true;
            }
        });

        return {
            isSbCollapsed,
            toggleSb,
            closeSb,
            verfied,
        };
    },
};
</script>

<style>
.page-wrapper {
    position: relative;
}

.page-wrapper .page-header {
    max-width: 100vw;
    position: fixed;
    top: 0;
    z-index: 9;
    -webkit-transition: 0.28s;
    transition: 0.28s;
    background-color: #fff;
}

.page-wrapper.compact-wrapper .page-header {
    margin-left: 280px;
    width: calc(100% - 280px);
}

.page-wrapper.compact-wrapper .page-header.closed {
    margin-left: 0;
    width: 100%;
}

.page-wrapper .page-body-wrapper {
    background-color: #f4f6fd;
}
</style>
