<template>
    <div class="col-auto p-0">
        <div class="toggle-sidebar" @click="$emit('on-toggle-menu')">
            <div class="status_toggle sidebar-toggle d-flex">
                <i class="far fa-caret-square-right" v-show="isMenuCollapsed"></i>
                <i class="far fa-caret-square-left" v-show="!isMenuCollapsed"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        isMenuCollapsed: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
};
</script>

<style scoped>
.toggle-sidebar {
    display: block;
}
.toggle-sidebar {
    cursor: pointer;
}
.toggle-sidebar > div {
    position: relative;
    top: -0.6rem;
}
.toggle-sidebar > div > i {
    position: absolute;
}
i {
    font-size: 1.3rem;
}

@media screen and (max-width: 992px) {
    .fa-caret-square-left {
        display: none;
    }
}
</style>
