<template>
    <div class="lds-ellipsis" v-if="isLoading">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
</template>

<script>
import useLoading from '../../../services/useLoading';

export default {
    name: 'Loading',
    setup() {
        const { isLoading } = useLoading();

        return { isLoading };
    },
};
</script>

<style scoped>
.lds-ellipsis {
    display: inline-block;
    position: relative;
    top: -11px;
    left: 22px;
    /* width: 80px;
    height: 80px; */
}
.lds-ellipsis div {
    position: absolute;
    /* top: 33px; */
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #2c323f;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}
</style>
