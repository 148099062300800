/**
item: {
    id: 1,
    title: 'Home',
    component: 'Home',
    status: OPEN | MINIMIZED
}
 */
import { defineAsyncComponent, markRaw } from 'vue';
import axios from '@/services/useAxios';
import Components from '@/DarminComponents';

const OPEN = true;
const MINIMIZED = false;

export default {
    namespaced: true,
    state: {
        home: {
            _id: 0,
            id: 0,
            name: 'Home',
            component: 'Home',
            childrens: [],
            params: {},
        },
        menuItems: [],
        windows: [],
    },
    mutations: {
        addWindow(state, item) {
            state.windows.push({ ...item, status: OPEN });
            // console.log('Item Pushed');
        },
        removeWindow(state, index) {
            state.windows.splice(index, 1);
        },
        minimizedAllWIndows(state) {
            // console.log('state.windows.length', state.windows.length);
            for (let i = 0; i < state.windows.length; i += 1) {
                state.windows[i].status = MINIMIZED;
            }
        },
        showWindow(state, item) {
            state.windows.find((w) => w.id === item.id).status = OPEN;
            for (let i = 0; i < state.windows.length; i += 1) {
                if (state.windows[i].id === item.id) {
                    state.windows[i].status = OPEN;
                }
            }
        },
        setMenuItems(state, items) {
            state.menuItems.splice(0);
            items.map((item) => {
                // console.log(item);
                state.menuItems.push(item);
                return item;
            });
        },
        resetState(state) {
            console.log('resetState');
            state.menuItems.splice(0);
            state.windows.splice(0);
        },
    },
    actions: {
        print: {
            handler(context, payload) {
                console.log('Action:', payload);
            },
        },
        openWindow: {
            async handler({ state, commit, dispatch }, item) {
                const components = await Components();
                if (
                    state.windows.filter((i) => {
                        if (item.id === i.id) {
                            return true;
                        }
                        return false;
                    }).length
                ) {
                    dispatch('showWindow', item);
                } else if (components.getComponent(item.component) !== undefined) {
                    const customComponent = defineAsyncComponent(() => {
                        try {
                            const component = components.getComponent(item.component).load();
                            return component;
                        } catch (error) {
                            return false;
                        }
                    });
                    // console.log(customComponent);
                    if (customComponent) {
                        const customComponentMarkRaw = markRaw(customComponent);
                        // console.log('params:', item.params);
                        commit('minimizedAllWIndows');
                        commit('addWindow', {
                            ...item,
                            app: customComponentMarkRaw,
                            params: item.params,
                        });
                    }
                } else {
                    throw Error();
                }
            },
        },
        showWindow: {
            handler(context, item) {
                context.commit('minimizedAllWIndows');
                context.commit('showWindow', item);
            },
        },
        hideAllWindows: {
            handler(context) {
                context.commit('minimizedAllWIndows');
            },
        },
        closeWindow: {
            handler({ state, commit, dispatch }, item) {
                if (
                    state.windows.filter((i) => {
                        if (item.id === i.id) {
                            return true;
                        }
                        return false;
                    }).length
                ) {
                    const index = state.windows.findIndex((i) => {
                        if (i.id === item.id) {
                            return true;
                        }
                        return false;
                    });
                    commit('removeWindow', index);
                    if (item.status) {
                        const windowsCount = state.windows.length;
                        if (windowsCount > 0) {
                            // Podemos Mostrar una ventana
                            let indexToShow = index;
                            if (indexToShow > 0) {
                                indexToShow -= 1;
                            }
                            const itemToShow = state.windows[indexToShow];
                            dispatch('showWindow', itemToShow);
                        }
                    }
                    // unMount vue app
                }
            },
        },
        getMenuItems: {
            async handler({ commit }) {
                // commit('setMenuItems', items);
                const res = await axios.get('/menu/tree/profile');
                commit('setMenuItems', res.data);
            },
        },
        openHome: {
            handler({ dispatch, state }) {
                dispatch('openWindow', state.home);
            },
        },
        logout: {
            handler({ commit }) {
                console.log('Logout');
                commit('resetState');
            },
        },
    },
};
