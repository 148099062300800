import axios from 'axios';
import useSocket from '@/services/useSocket';

const apiAuth = axios.create({
    baseURL: `${process.env.VUE_APP_MAIN_SERVICE}/auth`,
});

export default {
    namespaced: true,
    state: {
        user: null,
        token: '',
        refreshToken: '',
    },
    mutations: {
        setUser: (state, user) => {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        setToken: (state, token) => {
            state.token = token;
            localStorage.setItem('token', token);
        },
        setRefreshToken: (state, refreshToken) => {
            state.refreshToken = refreshToken;
            localStorage.setItem('refreshToken', refreshToken);
        },
        clearSessionData: (state) => {
            state.user = null;
            localStorage.removeItem('user');
            state.token = '';
            localStorage.removeItem('token');
            state.refreshToken = '';
            localStorage.removeItem('refreshToken');
        },
    },
    actions: {
        doLogin: {
            async handler({ dispatch }, { user, password }) {
                try {
                    const res = await apiAuth.post('/login', { user, password });
                    if (res.status === 200) {
                        dispatch('setAuthData', res.data);
                        return;
                    }
                    throw new Error(res.data.errors);
                } catch (error) {
                    throw error.response;
                }
            },
        },
        getSessionData: {
            async handler({ dispatch }) {
                return new Promise((resolve) => {
                    const user = JSON.parse(localStorage.getItem('user'));
                    const token = localStorage.getItem('token');
                    const refreshToken = localStorage.getItem('refreshToken');
                    if (user && token && refreshToken) {
                        dispatch('setAuthData', { user, token, refreshToken });
                    }
                    resolve();
                });
            },
        },
        setAuthData: {
            async handler({ commit }, payload) {
                commit('setUser', payload.user);
                commit('setToken', payload.token);
                commit('setRefreshToken', payload.refreshToken);
            },
        },
        logout: {
            async handler({ state, commit, dispatch }) {
                const { destroySocket } = useSocket();
                destroySocket();
                await apiAuth
                    .post('/logout', { refreshToken: state.refreshToken })
                    .catch((error) => {
                        throw error.response;
                    })
                    .finally(() => {
                        commit('clearSessionData');
                    });
                dispatch('ContentManager/logout', {}, { root: true });
                return true;
            },
        },
        doRefreshToken: {
            async handler({ state, commit }) {
                try {
                    const res = await apiAuth.post('/refresh-token', {
                        refreshToken: state.refreshToken,
                    });
                    if (res.status === 200) {
                        commit('setToken', res.data.token);
                        commit('setRefreshToken', res.data.refreshToken);
                    }
                } catch (error) {
                    throw error.response;
                }
            },
        },
        validateRecoveryToken: {
            async handler(context, token) {
                const res = await apiAuth.post('/recover/verify-token', {
                    token,
                });
                return res.status === 200;
            },
        },
        requestRecoverMail: {
            async handler(context, email) {
                try {
                    await apiAuth
                        .post('/recover', {
                            email,
                        })
                        .catch((error) => {
                            throw error;
                        });
                    return true;
                } catch (error) {
                    throw error.response;
                }
            },
        },
    },
    getters: {
        getToken: (state) => state.token,
        getRefreshToken: (state) => state.token,
        getUserId: (state) => state.user.id,
        getUserLanguage: (state) => state.user.setup.language,
        getUserObj: (state) => ({ ...state.user }),
    },
};
