<template>
    <LoginLayout>
        <RecoverForm />
    </LoginLayout>
</template>

<script>
import LoginLayout from '@/layouts/Login.layout.vue';
import RecoverForm from '@/components/login/RecoverForm.vue';

export default {
    components: { LoginLayout, RecoverForm },
};
</script>

<style></style>
