<template>
    <section>
        <div class="container-fluid">
            <div class="row">
                <div class="login-img d-none d-lg-flex col-lg-7"></div>
                <div class="content col-lg-5 p-0">
                    <slot></slot>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.login-img {
    background-image: url('../assets/images/login-bg.jpg');
    background-position: center;
    background-size: cover;
}

@media (max-width: 991px) {
    .content {
        background-image: url('../assets/images/login-bg.jpg');
        background-position: center;
        background-size: cover;
    }
}
</style>
