<template>
    <router-view />
    <vue3-snackbar bottom left :duration="5000"> </vue3-snackbar>
    <modals-container></modals-container>
</template>

<script>
import { onBeforeMount } from 'vue';
import { ModalsContainer } from 'vue-final-modal';
import useParameters from '@/services/useParameters';

import 'vue-final-modal/dist/style.css';

export default {
    name: 'DarminPlus',
    components: { ModalsContainer },
    setup() {
        const { getParameters } = useParameters();

        onBeforeMount(getParameters);
        return {};
    },
};
</script>
