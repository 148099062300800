<template>
    <li
        class="accordion-item"
        :id="'heading_' + item._id"
        v-if="item.childrens && item.childrens.length"
    >
        <div class="accordion-header" :id="'heading_' + item._id">
            <div
                class="accordion-button collapsed"
                data-bs-toggle="collapse"
                :data-bs-target="'#item_' + item._id"
                aria-expanded="false"
                :aria-controls="'item_' + item._id"
            >
                <div class="sidebar-link">{{ item.name }}</div>
            </div>
        </div>
        <div
            :id="'item_' + item._id"
            class="accordion-collapse collapse"
            :aria-labelledby="'heading_' + item._id"
            :data-bs-parent="parent"
        >
            <ul class="sidebar-submenu accordion" :id="'submenu_' + item._id">
                <MenuItem
                    v-for="subitem in item.childrens"
                    :key="subitem._id"
                    :item="subitem"
                    :parent="'#submenu_' + item._id"
                    @emitClick="emitClick"
                />
            </ul>
        </div>
    </li>
    <li v-else>
        <div class="sidebar-link" @click="$emit('emitClick', item)">{{ item.name }}</div>
    </li>
</template>

<script>
export default {
    props: {
        item: Object,
        parent: String,
    },
    setup(props, { emit }) {
        const emitClick = (item) => {
            emit('emitClick', item);
        };
        return { emitClick };
    },
};
</script>

<style></style>
