<template>
    <div
        class="d-flex align-items-center pestana"
        :data-idmenu="id"
        :id="'pestana_' + id"
        @click="handleShowWindow"
        @click.middle="handleCloseTab"
        :class="{ activa: item.status }"
    >
        <span class="d-flex">{{ item.name }}</span
        ><i v-if="item.id !== 0" class="far fa-times" @click="handleCloseTab"></i>
    </div>
</template>

<script>
export default {
    name: 'TabItem',
    props: {
        item: Object,
        id: Number,
    },
    setup(props, { emit }) {
        const handleShowWindow = () => {
            emit('onShowWindow', props.item);
        };
        const handleCloseTab = () => {
            if (props.item.id === 0) {
                return;
            }
            emit('onCloseTab', props.item);
        };

        return { handleShowWindow, handleCloseTab };
    },
};
</script>

<style></style>
