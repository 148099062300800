import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.VUE_APP_STATIC}`,
});

export default function useDownload() {
    const downloadPdf = async (url, label, type = 'application/pdf') => {
        const response = await api.get(url, { responseType: 'blob' });
        const blob = new Blob([response.data], { type });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = label;
        link.click();
        URL.revokeObjectURL(link.href);
    };

    return { downloadPdf };
}
