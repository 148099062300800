<template>
    <div class="login-card">
        <div class="login-form">
            <logo class="mb-5" />
            <h4>Login</h4>
            <h6>Bienvenidos a <b>Darmin Plus</b> Dashboard</h6>
            <div class="form-group">
                <label>Usuario</label>
                <div class="input-group">
                    <span class="input-group-text"><i class="far fa-envelope"></i></span>
                    <input
                        class="form-control"
                        type="email"
                        placeholder="usuario"
                        v-model="inputUser"
                    />
                </div>
            </div>
            <div class="form-group">
                <label>Contraseña</label>
                <div class="input-group">
                    <span class="input-group-text"><i class="far fa-lock-alt"></i></span>
                    <input
                        class="form-control"
                        :type="passwordType"
                        name="login[password]"
                        placeholder="********"
                        v-model="inputPass"
                        @keyup.enter="handleLogin"
                    />
                    <div class="show-hide" @click="toggleShowPass">
                        <span>
                            <i
                                class="far cursor-pointer"
                                :class="{
                                    'fa-eye': !showPass,
                                    'fa-eye-slash': showPass,
                                }"
                            >
                            </i>
                        </span>
                    </div>
                </div>
            </div>
            <div class="form-group text-end">
                <router-link class="link" to="/recover"> Recuperar contraseña </router-link>
            </div>
            <div class="form-group">
                <button class="btn btn-primary btn-block" type="submit" @click="handleLogin">
                    Entrar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useSnackbar } from 'vue3-snackbar';
import useAuth from '@/services/useAuth';

import logo from './logo.vue';

export default {
    components: { logo },
    setup() {
        const snackbar = useSnackbar();
        const router = useRouter();
        const store = useStore();
        const inputUser = ref('');
        const inputPass = ref('');
        const showPass = ref(false);

        const { checkUser, checkPass } = useAuth();

        const resetInputs = () => {
            inputUser.value = '';
            inputPass.value = '';
        };

        const handleLogin = async () => {
            // console.log('handleLogin');
            try {
                const user = await checkUser(inputUser.value);
                const password = await checkPass(inputPass.value);
                store
                    .dispatch('Auth/doLogin', { user, password })
                    .then(() => {
                        resetInputs();
                        router.push({ name: 'Home' });
                    })
                    .catch((error) => {
                        try {
                            error.data.errors.map((e) => {
                                snackbar.add({
                                    type: 'error',
                                    text: e.msg,
                                });
                                return true;
                            });
                        } catch (eerror) {
                            snackbar.add({
                                type: 'error',
                                text: 'DARMIN IS GONE',
                            });
                        }
                    });
            } catch (error) {
                snackbar.add({
                    type: 'error',
                    text: error.message,
                });
            }
        };

        const toggleShowPass = () => {
            showPass.value = !showPass.value;
        };

        const passwordType = computed(() => {
            if (showPass.value) {
                return 'text';
            }
            return 'password';
        });

        return {
            inputUser,
            inputPass,
            handleLogin,
            toggleShowPass,
            showPass,
            passwordType,
        };
    },
};
</script>

<style scoped>
.login-card {
    background-color: rgba(99, 98, 231, 0.1);
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 100vh;
    margin: 0 auto;
    padding: 30px 12px;
    font-family: 'Roboto', sans-serif;
}

.login-form {
    padding: 30px;
    width: 450px;
    background-color: #fff;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.login-form h4 {
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 22px;
}

.login-form h6 {
    font-size: 14px;
    margin-bottom: 25px;
    color: #999;
}

.login-form .form-group {
    margin-bottom: 20px;
}

.login-form .form-group label {
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 5px;
}

.login-form .form-group .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: var(--theme-deafult);
}

.login-form .btn {
    text-transform: uppercase;
    font-weight: 700;
    margin-left: auto;
    display: block;
    padding: 0.375rem 1.75rem;
}
.login-form .form-group .link {
    float: none;
}
</style>
