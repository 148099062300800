<template>
    <div class="login-card">
        <div class="login-form">
            <div class="mb-3 d-flex align-items-center">
                <router-link class="link" to="/login">
                    <i class="fas fa-arrow-left"></i> Cancelar
                </router-link>
                <logo class="ms-auto" size="xs" />
            </div>
            <h4>Recuperar Contraseña</h4>
            <h6>
                Ingrese su nueva contraseña, 2 veces para verificar y asignar su nueva contraseña
            </h6>
            <div class="form-group">
                <label>Nueva Contraseña</label>
                <div class="input-group">
                    <span class="input-group-text"><i class="far fa-lock-alt"></i></span>
                    <input
                        class="form-control"
                        type="password"
                        name="login[password]"
                        placeholder="********"
                        v-model="inputPass"
                        @keyup.enter="handleClick"
                    />
                    <div class="show-hide"><span class="show"> </span></div>
                </div>
            </div>
            <div class="form-group">
                <label>Repetir Contraseña</label>
                <div class="input-group">
                    <span class="input-group-text"><i class="far fa-lock-alt"></i></span>
                    <input
                        class="form-control"
                        type="password"
                        name="login[password]"
                        placeholder="********"
                        v-model="inputPass2"
                        @keyup.enter="handleClick"
                    />
                    <div class="show-hide"><span class="show"> </span></div>
                </div>
            </div>
            <div class="form-group">
                <button class="btn btn-primary btn-block" type="submit" @click="handleClick">
                    Enviar
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useAuth from '@/services/useAuth';
import logo from './logo.vue';

export default {
    components: { logo },
    props: { token: String },
    setup(props) {
        const router = useRouter();
        const store = useStore();
        const inputPass = ref('');
        const inputPass2 = ref('');

        const { checkPass, checkTwoPass } = useAuth();

        const resetInputs = () => {
            inputPass.value = '';
            inputPass2.value = '';
        };

        const handleClick = async () => {
            try {
                const password = await checkPass(inputPass.value);
                const password2 = await checkPass(inputPass2.value);
                const validPass = await checkTwoPass(password, password2);
                store
                    .dispatch('User/setNewPassword', { password: validPass, token: props.token })
                    .then(() => {
                        resetInputs();
                        router.push({ name: 'Login' });
                    })
                    .catch((err) => console.log(err));
            } catch (err) {
                console.log(err);
            }
        };

        onBeforeMount(async () => {
            store
                .dispatch('Auth/validateRecoveryToken', props.token)
                .then((res) => {
                    if (!res) {
                        router.push({ name: 'Login' });
                    }
                })
                .catch(() => router.push({ name: 'Login' }));
        });

        return { inputPass, inputPass2, handleClick };
    },
};
</script>

<style scoped>
.login-card {
    background-color: rgba(99, 98, 231, 0.1);
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 100vh;
    margin: 0 auto;
    padding: 30px 12px;
    font-family: 'Roboto', sans-serif;
}

.login-form {
    padding: 30px;
    width: 450px;
    background-color: #fff;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.login-form h4 {
    text-transform: capitalize;
    font-weight: 600;
    margin-bottom: 5px;
    font-size: 22px;
}

.login-form h6 {
    font-size: 14px;
    margin-bottom: 25px;
    color: #999;
}

.login-form .form-group {
    margin-bottom: 20px;
}

.login-form .form-group label {
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 5px;
}

.login-form .form-group .input-group-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    background-color: #e9ecef;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: var(--theme-deafult);
}

.login-form .btn {
    text-transform: uppercase;
    font-weight: 700;
    margin-left: auto;
    display: block;
    padding: 0.375rem 1.75rem;
}
</style>
