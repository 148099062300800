<template>
    <div>
        <LogoWrapper
            class="logo-wrapper d-flex align-items-center"
            @on-toggle-menu="$emit('on-toggle-menu')"
        ></LogoWrapper>
        <nav class="sidebar-main">
            <div id="sidebar-menu">
                <SidebarMenu @onClickIten="$emit('onClickIten')"></SidebarMenu>
            </div>
        </nav>
    </div>
</template>

<script>
import LogoWrapper from './LogoWrapper.vue';
import SidebarMenu from './SidebarMenu.vue';

export default {
    name: 'SidebarContainer',
    components: {
        LogoWrapper,
        SidebarMenu,
    },
};
</script>

<style scoped>
.logo-wrapper {
    height: 62px;
    border-bottom: 0.1px solid rgb(210 212 219 / 20%);
    box-shadow: -7px 1px 10px -4px rgb(0 0 0 / 20%);
}

.logo-wrapper,
.logo-icon-wrapper {
    line-height: inherit;
    padding: 0px 25px;
    text-align: left;
}

.sidebar-main {
    padding-top: 10px;
}
.sidebar-main .sidebar-links {
    height: calc(100vh - 76px);
    left: -300px;
    z-index: 99;
    -webkit-transition: color 1s ease;
    transition: color 1s ease;
    overflow: auto;
    color: rgba(0, 0, 0, 0);
    /* margin-top: 20px; */
    margin-bottom: 50px;
}
</style>
