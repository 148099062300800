import DocumentViewer from '../views/DocumentViewer.vue';
import HistoryViewer from '../Consulta/HistoryViewer.vue';

export default [
    {
        path: '/documento/examen/:resultKey?',
        name: 'ExamenViewer',
        component: DocumentViewer,
        props: true,
        meta: {
            title: 'Descargar Resultados | Darmin Plus',
        },
    },
    {
        path: '/documento/historia/:resultKey?',
        name: 'HistoriaViewer',
        component: HistoryViewer,
        props: true,
        meta: {
            title: 'Descargar Historia | Darmin Plus',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        redirect: () => ({ path: '/login' }),
    },
];
