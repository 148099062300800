<template>
    <div class="tab-wrapper">
        <div class="tab-container d-flex flex-wrap align-items-left">
            <TabItem
                v-for="(window, i) in windows"
                :key="i"
                :id="i"
                :item="window"
                @onShowWindow="handleShowWindow"
                @onCloseTab="handleCloseTab"
            />
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex';
import TabItem from './TabItem.vue';

export default {
    name: 'TabsContainer',
    components: { TabItem },
    setup() {
        const store = useStore();

        const { windows } = store.state.ContentManager;
        const handleShowWindow = (item) => {
            store.dispatch('ContentManager/showWindow', item);
        };
        const handleCloseTab = (item) => {
            store.dispatch('ContentManager/closeWindow', item);
        };

        return { windows, handleShowWindow, handleCloseTab };
    },
};
</script>

<style>
.tab-wrapper {
    margin-top: 60px;
    margin-left: 280px;
    padding-top: 5px;
    background-color: #edeef3;
    position: fixed;
    width: 100%;
    z-index: 1;
    -webkit-transition: all 0.27s ease;
    transition: all 0.27s ease;
    overflow-x: auto;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.tab-wrapper::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.tab-wrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}
.tab-container {
    min-height: 35px;
    font-family: rubik;
    border-bottom: 1px solid #d1d3da;
    padding-left: 8px;
    padding-right: 5px;
}
div.pestana {
    max-width: 175px;
    padding: 5px 10px;
    border-top: 1px solid #ddd;
    border-top-right-radius: 7px;
    border-top-left-radius: 7px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    transition: background-color 0.16s linear, color 0.15s linear, height 0.16s linear;
    -webkit-transition: background-color 0.16s linear, color 0.15s linear, height 0.16s linear;
    margin-top: 3px;
    margin-left: -2px;
    margin-right: 1px;
    background-color: #fff;
    color: #212121;
    font-size: 0.9rem;
    /* min-height: 33px; */
}
div.pestana:first-child {
    margin-left: 0;
}
div.pestana:hover {
    background-color: rgb(235, 235, 235);
}
.pestana.activa {
    background-color: #f4f6fd;
    color: #2c323f;
    box-shadow: 0px -5px 8px -8.5px #000;
    transition: background-color 0.16s;
    -webkit-transition: background-color 0.16s;
    position: relative;
    top: 1px;
    /* min-height: 34px; */
}
.pestana.activa:hover {
    background: linear-gradient(to bottom, #ffffff 25%, #f4f6fd);
}
div.pestana span {
    max-width: 180px;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 4px;
    cursor: pointer;
}
div.pestana i {
    font-size: 1.2rem;
    cursor: pointer;
}
div.pestana i {
    color: #b2b2b2;
}
div.pestana i:hover {
    color: #787a83;
}
div.pestana.activa i {
    color: #9498a8;
}
div.pestana.activa > * {
    margin-top: -1px;
}
</style>
