import axios from 'axios';
// import api from '@/services/useAxios';

const apiUser = axios.create({
    baseURL: `${process.env.VUE_APP_MAIN_SERVICE}/users`,
});

export default {
    namespaced: true,
    actions: {
        setNewPassword: {
            async handler(context, { password, token }) {
                const res = await apiUser.post('/set-password', {
                    password,
                    token,
                });

                return res.status === 200;
            },
        },
    },
};
