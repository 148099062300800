<template>
    <ul class="sidebar-links accordion" id="sidebarAccordion">
        <RootItem v-for="item in menuItems" :key="item._id" :item="item" @onClickItem="onOpenTab" />
    </ul>
</template>

<script>
import { onMounted, onBeforeUnmount } from 'vue';
import { useStore } from 'vuex';
import { useSnackbar } from 'vue3-snackbar';
import useSocket from '@/services/useSocket';
import RootItem from './item/RootItem.vue';

export default {
    components: {
        RootItem,
    },
    setup(props, { emit }) {
        const { socket } = useSocket();
        const snackbar = useSnackbar();
        const store = useStore();
        const { menuItems } = store.state.ContentManager;

        const onOpenTab = (item) => {
            // console.log('OpenTabs');
            // console.log('item:', item);
            store.dispatch('ContentManager/openWindow', item).catch(() => {
                snackbar.add({
                    type: 'warning',
                    text: 'Contenido no disponible',
                });
            });
            emit('onClickIten', { type: 'menuItem' });
        };

        onMounted(() => {
            store.dispatch('ContentManager/getMenuItems');
        });

        socket.on('rte:MenuEditor', async ({ action }) => {
            switch (action) {
                case 'reload:list':
                    // console.log('Lista actualizada SidebarMenu');
                    store.dispatch('ContentManager/getMenuItems');
                    break;
                default:
                    // console.log('rte:Default');
                    break;
            }
        });

        onBeforeUnmount(() => {
            socket.off('rte:MenuEditor');
        });
        return {
            menuItems,
            onOpenTab,
        };
    },
};
</script>

<style scoped>
.sidebar-links {
    height: calc(100vh - 76px);
    left: -300px;
    z-index: 99;
    -webkit-transition: color 1s ease;
    transition: color 1s ease;
    overflow: auto;
    color: rgba(0, 0, 0, 0);
    /* margin-top: 20px; */
    margin-bottom: 50px;
}
/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}
</style>
