<template>
    <div>
        <div class="header-wrapper row m-0">
            <HeaderMenuToogleWrapper
                class="header-menu-toggle-wrapper"
                @on-toggle-menu="$emit('on-toggle-menu')"
                :isMenuCollapsed="isMenuCollapsed"
            ></HeaderMenuToogleWrapper>
            <div class="col-auto p-0"><Loading class="loading" /></div>

            <HeaderMenu class="nav-right col-10 col-sm-6 pull-right right-header p-0"></HeaderMenu>
        </div>
    </div>
</template>

<script>
import Loading from './Loading.vue';
import HeaderMenuToogleWrapper from './HeaderMenuToogleWrapper.vue';
import HeaderMenu from './HeaderMenu.vue';

export default {
    components: {
        HeaderMenuToogleWrapper,
        HeaderMenu,
        Loading,
    },
    props: {
        isMenuCollapsed: {
            type: Boolean,
            default: false,
            required: true,
        },
    },
};
</script>

<style scoped>
.header-wrapper {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 13px 30px;
    position: relative;
    background-color: #fff;
}

.nav-right {
    margin-left: auto;
}

.header-wrapper .nav-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
</style>
