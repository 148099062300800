import 'vue3-treeselect/dist/vue3-treeselect.css';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import { createApp } from 'vue';
import { SnackbarService, Vue3Snackbar } from 'vue3-snackbar';
import 'vue3-snackbar/dist/style.css';
import Store from '@/store';
import router from '@/router';
import App from '@/App.vue';
import './assets/css/darmin/style.css';
import axiosSetup from '@/middlewares/axios_interceptor';
import { createVfm } from 'vue-final-modal';
import Hotjar from 'vue-hotjar';

const vfm = createVfm();
axiosSetup(Store);
Store.dispatch('Auth/getSessionData').then(() => {
    createApp(App)
        .use(Hotjar, {
            id: process.env.VUE_APP_HOTJAR_ID,
            isProduction: process.env.NODE_ENV === 'production',
            snippetVersion: 6,
        })
        .use(router)
        .use(Store)
        .use(SnackbarService)
        .use(vfm)
        .component('vue3-snackbar', Vue3Snackbar)
        .mount('#app');
});
