import { ref } from 'vue';
import axios from 'axios';

const api = axios.create({
    baseURL: `${process.env.VUE_APP_STATIC}`,
});

export default function useRenders() {
    const renders = ref();
    const getRenders = async (key) => {
        const res = await api.get(`/resultado/${key}`);
        if (res.status === 200) {
            // console.log(res.data);
            renders.value = res.data;
        } else {
            throw new Error(res.data.errors);
        }
    };
    return { renders, getRenders };
}
