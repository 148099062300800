import { ref } from 'vue';

const width = ref(document.documentElement.clientWidth);
const height = ref(document.documentElement.clientHeight);

window.addEventListener('resize', () => {
    console.log('listener');
    width.value = document.documentElement.clientWidth;
    height.value = document.documentElement.clientHeight;
});

const useResize = () => ({ width, height });
export default useResize;
