import { ref } from 'vue';

const isLoading = ref(false);

export default function useLoading() {
    const setLoadingOn = () => {
        isLoading.value = true;
    };
    const setLoadingOff = () => {
        isLoading.value = false;
    };

    return { isLoading, setLoadingOn, setLoadingOff };
}
