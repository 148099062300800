import { createStore } from 'vuex';
import axios from '@/services/useAxios';
import ContentManager from './ContentManager';
import Auth from './Auth';
import Menu from './Menu';
import User from './User';

export default createStore({
    state: () => ({
        v: 1.0,
    }),
    mutations: {},
    actions: {
        doVerifySession: {
            async handler() {
                const res = await axios.post('/auth/verify-session');
                if (res.status === 200) {
                    return true;
                }
                throw new Error('Session no valid');
            },
        },
    },
    modules: {
        ContentManager,
        Auth,
        Menu,
        User,
    },
});
