<template>
    <LoginLayout>
        <PasswordForm :token="token" />
    </LoginLayout>
</template>

<script>
import LoginLayout from '@/layouts/Login.layout.vue';
import PasswordForm from '@/components/login/PasswordForm.vue';

export default {
    components: { LoginLayout, PasswordForm },
    props: { token: String },
};
</script>
