import { io } from 'socket.io-client';

let socket = null;

const createSocket = (token) => {
    if (socket == null && token !== undefined) {
        socket = io(process.env.VUE_APP_SOCKET_SERVICE, {
            extraHeaders: {
                'x-token': token,
            },
            reconnection: true, // Habilita la reconexión automática
            reconnectionAttempts: Infinity, // Número máximo de intentos de reconexión (Infinity para ilimitados)
            reconnectionDelay: 1000, // Tiempo de espera inicial en ms entre intentos
            reconnectionDelayMax: 5000, // Tiempo máximo de espera entre intentos
            randomizationFactor: 0.5, // Factor de randomización para el retraso entre intentos
        });
        socket.on('connect', () => {
            // const { engine } = socket.io;
            // console.log(engine.transport.name); // in most cases, prints "polling"

            // engine.on('packet', ({ type, data }) => {
            //     // called for each packet received
            //     console.log('packet');
            //     console.log({ type, data });
            // });
        });
    }
    return socket;
};

const destroySocket = () => {
    socket.disconnect();
    socket = null;
};

const useSocket = () => ({
    socket,
    createSocket,
    destroySocket,
});

export default useSocket;
