<template>
    <li class="">
        <div
            class="media profile-media dropdown-toggle cursor-pointer"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            id="profile-media"
        >
            <i class="far fa-ellipsis-v-alt"></i>
        </div>
        <ul class="dropdown-menu" aria-labelledby="profile-media">
            <li><span class="" type="button">Action</span></li>
            <li @click="handleOpenUserSetup">
                <span class="" type="button">Mi Perfil</span>
            </li>
            <li @click="handleLogout">
                <i class="fas fa-sign-out"></i>
                <span class="" type="button"> Log out</span>
            </li>
            <li v-if="env === 'development'">{{ env }}</li>
        </ul>
    </li>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
    name: 'UseMenu',
    setup() {
        const router = useRouter();
        const store = useStore();

        const handleOpenUserSetup = () => {
            store.dispatch('ContentManager/openWindow', {
                id: `UserForm_${store.getters['Auth/getUserId']}`,
                name: 'Editar Usuario',
                component: 'UserForm',
                params: {
                    urlRead: '/users',
                    item: { id: store.getters['Auth/getUserId'] },
                },
            });
        };

        const handleLogout = async () => {
            await store.dispatch('Auth/logout');
            router.push({ name: 'Login' });
        };
        return { handleLogout, handleOpenUserSetup, env: process.env.NODE_ENV };
    },
};
</script>

<style scoped>
li {
    padding: 6px 10px;
    display: flex;
    position: relative;
}

i {
    font-size: 1.2rem;
}
span {
    margin-left: 5px;
}

.media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.media::after {
    display: none !important;
}

.profile-media i {
    font-size: 1.4rem;
}
.dropdown-menu {
    z-index: 1000000;
}
</style>
