import axios from 'axios';
import useLoading from './useLoading';

const { setLoadingOn, setLoadingOff } = useLoading();

const instance = axios.create({
    baseURL: process.env.VUE_APP_MAIN_SERVICE,
    headers: {
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use(
    (config) => {
        // if (isLoading.value) {
        //     throw new axios.Cancel('Bussy');
        // }
        setLoadingOn();
        return config;
    },
    (error) => {
        setLoadingOff();
        return Promise.reject(error);
        // eslint-disable-next-line comma-dangle
    }
);

instance.interceptors.response.use(
    (config) => {
        setLoadingOff();
        return config;
    },
    (error) => {
        setLoadingOff();
        return Promise.reject(error);
        // eslint-disable-next-line comma-dangle
    }
);

export default instance;
