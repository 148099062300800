const re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@!"#$%&()=?*+.])[A-Za-z\d@!"#$%&()=?*+.]{8,}$/;
// eslint-disable-next-line operator-linebreak
const rm =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

const useAuth = () => {
    const checkLogin = async () => {};

    const checkUser = async (input) => {
        if (input.length === 0) {
            throw new Error('USERNAME_REQUIRED');
        }

        return input;
    };

    const checkPass = async (input) => {
        if (input.length === 0) {
            throw new Error('PASSWORD_REQUIRED');
        }

        if (!re.test(input)) {
            throw new Error('PASSWORD_SECURITY_POLICY');
        }
        return input;
    };

    const checkTwoPass = async (input, input2) => {
        if (input !== input2) {
            throw new Error('PASSWORD_NO_EQUAL');
        }
        return input;
    };

    const checkMail = async (input) => {
        if (String(input).toLowerCase().match(rm)) {
            return input;
        }
        throw new Error('INVALID_EMAIL');
    };

    return {
        checkLogin,
        checkUser,
        checkPass,
        checkTwoPass,
        checkMail,
    };
};

export default useAuth;
