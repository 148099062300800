<template>
    <section>
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="content col-lg-5 p-0">
                    <div class="login-card">
                        <div class="login-form">
                            <logo class="mb-5" />
                            <h4 class="mb-3">Resultados de Exámenes</h4>

                            <div v-if="!renders">
                                <div class="form-group mb-3">
                                    <label for="">Clave secreta</label>
                                    <div class="input-group">
                                        <span class="input-group-text">
                                            <i class="far fa-lock-alt"></i>
                                        </span>
                                        <input class="form-control" type="text" v-model="key" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <button
                                        class="btn btn-primary btn-block"
                                        type="submit"
                                        @click="handlerLoadResultado"
                                        :disabled="key === ''"
                                    >
                                        Entrar
                                    </button>
                                </div>
                            </div>
                            <div v-else>
                                <div class="files">
                                    <div
                                        class="file d-flex align-items-center mb-1 p-3"
                                        v-for="(r, i) in renders"
                                        :key="i"
                                        @click="handleDownloadDocument(r.id, r.title)"
                                    >
                                        <img
                                            src="@/assets/images/media-icons/pdf.png"
                                            alt="Imagen"
                                            class="img-fluid media-icon"
                                        />
                                        <span class="ms-2 title">{{ r.title }}</span>
                                        <div class="ms-auto">
                                            <i class="ms-1 far fa-download"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { ref, onBeforeMount } from 'vue';
import Logo from '@/components/login/logo.vue';

import useRenders from '@/modules/Care/Resultados/services/useRenders';
import useDownload from '@/services/useDownload';

export default {
    name: 'DocumentViewer',
    components: { Logo },
    props: {
        resultKey: String,
    },
    setup(props) {
        const key = ref(props.resultKey || '');

        const { renders, getRenders } = useRenders();
        const { downloadPdf } = useDownload();

        onBeforeMount(() => {
            // console.log(key.value);
            if (key.value) {
                getRenders(key.value);
            }
        });

        const handlerLoadResultado = async () => {
            if (key.value) {
                getRenders(key.value);
            }
        };

        const handleDownloadDocument = (id, label) => {
            const url = `${process.env.VUE_APP_STATIC}/resultado/document/${id}`;
            downloadPdf(url, label);
        };

        return {
            key,
            renders,
            handlerLoadResultado,
            handleDownloadDocument,
        };
    },
};
</script>

<style scoped>
.login-card {
    background-color: rgba(99, 98, 231, 0.1);
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    min-height: 100vh;
    margin: 0 auto;
    padding: 30px 12px;
    font-family: 'Roboto', sans-serif;
}
.login-form {
    padding: 30px;
    width: 450px;
    background-color: #fff;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.files .file {
    cursor: pointer;
    border: 1px solid #efefef;
    border-radius: 5px;
    background-color: #fdfdfd;
}
.files .file:hover {
    background-color: #efefef;
    border-color: #fdfdfd;
}
.files .media-icon {
    max-width: 3em;
}
.files .title {
    margin: 0;
}
.files i {
    cursor: pointer;
}
</style>
