<template>
    <div class="darmin page-body" :class="{ loading: isLoading }">
        <component
            v-for="(tab, i) in windows"
            :key="i"
            :ref="prefix + tab.id"
            :id="prefix + tab.id"
            :is="tab.app"
            v-bind="tab.params"
            class="content-body w-100 h-100"
            :class="{ current: tab.status }"
            @onClose="handleCloseTab(tab)"
        />
    </div>
</template>

<script>
import { onMounted } from 'vue';
import { useStore } from 'vuex';
import useLoading from '../../../services/useLoading';

export default {
    name: 'PageBody',
    setup() {
        const prefix = 'component_';

        const store = useStore();

        const { windows } = store.state.ContentManager;

        const { isLoading } = useLoading();

        const handleCloseTab = (item) => {
            store.dispatch('ContentManager/closeWindow', item);
        };

        onMounted(() => {
            // console.log('the component is now mounted.');
        });
        return {
            prefix,
            windows,
            isLoading,
            handleCloseTab,
        };
    },
};
</script>

<style scoped>
.page-wrapper .page-body-wrapper .darmin.page-body {
    height: calc(100vh - 108px);
    margin-top: 108px;
    margin-left: 280px;
    padding: 0 0 0 15px;
    position: fixed;
    width: calc(100% - 280px);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.content-body {
    display: none;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    /* padding-bottom: 110px; */
    padding-right: 30px;
    padding-bottom: calc(1vh);
}

/* width */
.content-body::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.content-body::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.content-body::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
.content-body::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.content-body.current {
    display: block;
}
</style>
