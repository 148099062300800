<template>
    <li class="sidebar-list accordion-item">
        <div class="accordion-header" :id="'heading_' + item._id" v-if="item.childrensCount">
            <div class="sidebar-link sidebar-title">
                <div
                    class="accordion-button collapsed"
                    data-bs-toggle="collapse"
                    :data-bs-target="'#item_' + item._id"
                    aria-expanded="false"
                    :aria-controls="'item_' + item._id"
                >
                    <i class="far fa-folder-tree"></i>
                    <span class="lan-3">{{ item.name }}</span>
                </div>
            </div>
        </div>
        <div
            v-if="item.childrens.length"
            :id="'item_' + item._id"
            class="accordion-collapse collapse"
            :aria-labelledby="'heading_' + item._id"
            data-bs-parent="#sidebarAccordion"
        >
            <ul class="sidebar-submenu" :id="'submenu_' + item._id">
                <MenuItem
                    v-for="subitem in item.childrens"
                    :key="subitem._id"
                    :item="subitem"
                    :parent="'#submenu_' + item._id"
                    @emitClick="emitClick"
                />
            </ul>
        </div>
        <div class="sidebar-link sidebar-title" v-else @click="$emit('onClickItem', item)">
            <div class="d-flex align-items-center">
                <i class="far fa-browser"></i>
                <span>{{ item.name }}</span>
            </div>
        </div>
    </li>
</template>

<script>
import MenuItem from './MenuItem.vue';

export default {
    components: {
        MenuItem,
    },
    props: {
        item: Object,
    },
    setup(props, { emit }) {
        const emitClick = (item) => {
            emit('onClickItem', item);
        };
        return { emitClick };
    },
};
</script>

<style></style>
