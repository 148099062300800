<template>
    <div class="text-center" :class="'size-' + size">
        <img class="img-fluid" :src="logoImg" alt="" srcset="" />
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, onBeforeMount, watch, onMounted } from 'vue';
import useParameters from '@/services/useParameters';
import logo from '@/assets/darmin_plus_full.svg';

export default {
    props: {
        size: {
            type: String,
            default: 'md',
        },
    },
    setup() {
        const { parameters, getParameters, returnParameter } = useParameters();
        const logoImg = ref();

        const setLogo = () => {
            const p = returnParameter('logo_v');
            console.log(p);
            if (p) {
                logoImg.value = `${process.env.VUE_APP_STATIC}/uploads/${p}`;
            } else {
                logoImg.value = logo;
            }
        };
        onBeforeMount(async () => {
            await getParameters();
        });

        onMounted(() => {
            if (parameters) {
                console.log('defined');
                setLogo();
            }
        });

        watch(
            () => parameters.value,
            () => {
                console.log('logoWrapper');
                console.log(parameters.value);
                setLogo();
                // eslint-disable-next-line comma-dangle
            }
        );
        return { logoImg };
    },
};
</script>

<style>
.size-xs img {
    max-width: 50px;
}
.size-sm img {
    max-width: 100px;
}
.size-md img {
    max-width: 200px;
}
.size-lg img {
    max-width: 300px;
}
</style>
