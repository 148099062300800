<template>
    <div>
        <div class="flex-grow-1 text-center">
            <img v-if="tipoLogo === 'imagen'" class="img-fluid for-light" :src="logoImg" alt="" />
            <div
                v-if="tipoLogo === 'texto'"
                style="font-family: 'Rubik'; font-weight: 500; font-size: 1.2em"
            >
                {{ customer }}
            </div>
        </div>
        <div class="">
            <i
                class="far fa-2x fa-angle-left mt-1 d-lg-none cursor-pointer"
                @click="$emit('on-toggle-menu')"
            ></i>
        </div>
    </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, onBeforeMount, watch, onMounted } from 'vue';
import useParameters from '@/services/useParameters';
import logo from '@/assets/darmin_plus_full.svg';

export default {
    name: 'LogoWrapper',
    setup() {
        const { parameters, getParameters, returnParameter } = useParameters();
        const logoImg = ref();
        const tipoLogo = ref('imagen');
        const customer = ref('');

        const setLogo = () => {
            try {
                tipoLogo.value = returnParameter('tipo_logo');
            } catch (err) {
                console.log('tipoLogo cant be set');
            }

            switch (tipoLogo.value) {
                case 'texto':
                    customer.value = returnParameter('customer');
                    break;
                default:
                case 'imagen':
                    // eslint-disable-next-line no-case-declarations
                    const p = returnParameter('logo_h');
                    console.log(p);
                    if (p) {
                        logoImg.value = `${process.env.VUE_APP_STATIC}/uploads/${p}`;
                    } else {
                        logoImg.value = logo;
                    }
                    break;
            }
        };
        onBeforeMount(async () => {
            await getParameters();
        });

        onMounted(() => {
            if (parameters) {
                console.log('defined');
                setLogo();
            }
        });

        watch(
            () => parameters.value,
            () => {
                console.log('logoWrapper');
                console.log(parameters.value);
                setLogo();
                // eslint-disable-next-line comma-dangle
            }
        );

        return { logoImg, tipoLogo, customer };
    },
};
</script>

<style scoped>
img {
    display: inline-block;
    height: 45px;
}

@media only screen and (min-width: 576px) {
    .sidebar-wrapper .back-btn {
        display: none;
    }
}
</style>
