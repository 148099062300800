<template>
    <div>
        <ul class="nav-menus">
            <!-- <li>
                <div class="mode animated backOutRight">
                    <i class="darkicon far fa-sun"></i>
                    <i class="lighticon far fa-moon"></i>
                </div>
            </li>
            <li class="cart-nav onhover-dropdown">
                <div class="cart-box">
                    <i class="far fa-shopping-cart"></i>
                    <span class="badge rounded-pill badge-primary">2</span>
                </div>
            </li>
            <li class="onhover-dropdown">
                <div class="notification-box">
                    <i class="far fa-bookmark"></i>
                </div>
            </li> -->
            <li class="onhover-dropdown">
                <div class="notification-box">
                    <i class="far fa-bell"></i>
                    <span class="badge rounded-pill badge-warning">0</span>
                </div>
            </li>
            <li class="maximize d-none">
                <i class="fas fa-expand"></i>
            </li>
            <UserMenu></UserMenu>
        </ul>
    </div>
</template>

<script>
import UserMenu from './items/UserMenu.vue';

export default {
    components: { UserMenu },
};
</script>

<style scoped>
i {
    font-size: 1.2rem;
}
.profile-media i {
    font-size: 1.4rem;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.nav-right > ul > li {
    padding: 6px 10px;
    margin-right: 5px;
    display: inline-block;
    position: relative;
}
.nav-right.right-header ul li .mode {
    cursor: pointer;
    width: 20px;
    max-height: 25px;
    text-align: center;
}
.nav-right.right-header ul li .mode .darkicon {
    stroke: #fff;
    stroke-width: 1.8px;
    position: absolute;
    top: 5px;
    left: 9px;
    opacity: 0;
}
.nav-right ul li svg {
    vertical-align: middle;
}

.nav-right .notification-box {
    position: relative;
}

.nav-right > ul > li .badge {
    position: absolute;
    right: -5px;
    top: -6px;
    padding: 2px 4px;
    font-size: 11px;
    font-weight: 700;
    font-family: work-Sans, sans-serif;
}
.nav-right .profile-nav .media {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
</style>
