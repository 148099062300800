import axios from '@/services/useAxios';

export default {
    namespaced: true,
    actions: {
        getMenuItems: {
            async handler({ dispatch }) {
                try {
                    axios
                        .get('/menu/tree/profile')
                        .then((res) => {
                            dispatch('ContentManager/setMenuItems', res.data, { root: true });
                        })
                        .catch((error) => {
                            console.log('nojoda', error);
                        });
                } catch (error) {
                    console.log('nojodaaa', error);
                }
            },
        },
    },
};
